/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { PureComponent } from "react";
import { Alert } from "reactstrap";
import {
    faSpinner,
    faExclamationTriangle,
    faCheck,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Alert Component to show following messages:
 * Loading message, Success message, Error message, Warning message.
 * Expects props as
 * msg = {msgLoading: '', msgSuccess: '', msgError: '', msgWarning: ''}
 */

class AlertComponent extends PureComponent {
    render() {
        return (
            <div>
                {this.props.msg.msgLoading != "" ? (
                    <Alert color="info" className="mt-2 mb-2">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-pulse fa-fw"
                        />{" "}
                        {this.props.msg.msgLoading}
                    </Alert>
                ) : (
                    ""
                )}
                {this.props.msg.msgSuccess != "" ? (
                    <Alert color="success" className="mt-2 mb-2 autohide">
                        <FontAwesomeIcon icon={faCheck} className="fa-fw" />{" "}
                        {this.props.msg.msgSuccess}
                    </Alert>
                ) : (
                    ""
                )}
                {this.props.msg.msgError != "" ? (
                    <Alert color="danger" className="mt-2 mb-2">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="fa-fw"
                        />{" "}
                        {this.props.msg.msgError}
                    </Alert>
                ) : (
                    ""
                )}
                {this.props.msg.msgWarning != "" ? (
                    <Alert color="warning" className="mt-2 mb-2">
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="fa-fw"
                        />{" "}
                        {this.props.msg.msgWarning}
                    </Alert>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default AlertComponent;
